<template>
    <div class="box">
        <h2>
            Prüfungsliste bearbeiten
            <small>#{{ crefoChecklist.id }}</small>
        </h2>
        <div class="body">
            <form @submit.prevent="save()">
                <div class="form-group form-float">
                    <input v-model="crefoChecklist.label" v-validate="'required'" id="label" name="label" placeholder=" " type="text" />
                    <label for="title">Bezeichner</label>
                    <span class="error" v-show="errors.has('label')">{{ errors.first("label") }}</span>
                </div>
                <div>
                    <dl>
                        <dt>Type:</dt>
                        <dd v-show="crefoChecklist.type == 'list'">
                            Crefo Listenbasiert: <b>{{ crefoChecklist.keylist.key }}</b>
                        </dd>
                        <dd v-show="crefoChecklist.type == 'freetext'">Freitext</dd>
                    </dl>
                </div>

                <hr />

                <div v-if="crefoChecklist.type == 'list'">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Key</th>
                                <th>Name</th>
                                <th>Kurzform</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="clickable-row" v-for="entry in crefoChecklist.keylist.entries" v-bind:key="entry.key">
                                <td><input type="checkbox" v-model="entry.enabled" :id="'entry_' + entry.key" /><label :for="'entry_' + entry.key"></label></td>
                                <td>{{ entry.key }}</td>
                                <td>{{ entry.designation }}</td>
                                <td>{{ entry.shortdesignation }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="crefoChecklist.type == 'freetext'">
                    <table>
                        <thead>
                            <tr>
                                <th>Eintrag</th>
                                <th>Notiz</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(entry, index) in crefoChecklist.freetext" v-bind:key="index">
                                <td>
                                    <div class="form-group form-float no-error">
                                        <input v-model="entry.value" :id="'freetext_value_' + index" placeholder=" " type="text" />
                                        <label :for="'freetext_value_' + index">Eintrag</label>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group form-float no-error">
                                        <input v-model="entry.note" :id="'freetext_note_' + index" placeholder=" " type="text" />
                                        <label :for="'freetext_note_' + index">Eintrag</label>
                                    </div>
                                </td>
                                <td><i @click="removeFreetext(index)" class="delete fal fa-trash-alt"></i></td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <span class="add" @click="addFreetext()"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <hr />

                <div class="button-row">
                    <button type="submit" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            crefoChecklist: {
                id: "",
                label: "",
                type: "",
                freetext: [],
                keylist: {},
                accepted: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        addFreetext() {
            this.crefoChecklist.freetext.push({ value: "", note: "" });
        },
        removeFreetext(index) {
            this.crefoChecklist.freetext.splice(index, 1);
        },
        save() {
            this.$validator.validateAll().then(valid => {
                if (!valid) {
                    return;
                }

                var keylist;
                if (this.crefoChecklist.keylist.key) {
                    keylist = this.crefoChecklist.keylist.key;
                }

                var freetext = [];
                for (var f = 0; f < this.crefoChecklist.freetext.length; f++) {
                    freetext.push({ value: this.crefoChecklist.freetext[f].value, note: this.crefoChecklist.freetext[f].note });
                }

                var accepted = [];
                for (var e = 0; e < this.crefoChecklist.keylist.entries.length; e++) {
                    if (this.crefoChecklist.keylist.entries[e].enabled) {
                        accepted.push(this.crefoChecklist.keylist.entries[e].key);
                    }
                }

                let request = {
                    label: this.crefoChecklist.label,
                    type: this.crefoChecklist.type,
                    keylist: keylist,
                    freetext: freetext,
                    accepted: accepted
                };

                this.$api.put("/crefo/checklist/" + this.$route.params.id, request).then(
                    () => {
                        // Checklist created
                        this.$snotify.success("Checkliste gespeichert");
                    },
                    () => {
                        // Create failed
                        this.$snotify.error("Aktualisierung fehlgeschlagen");
                    }
                );
            });
        },
        fetchData() {
            this.$api.get("/crefo/checklist/" + this.$route.params.id).then(
                response => {
                    this.crefoChecklist = response.data.data;

                    if (this.crefoChecklist.freetext === null) {
                        this.crefoChecklist.freetext = [];
                    }

                    // Use "allowed" to add enabled state to entries
                    for (var e = 0; e < this.crefoChecklist.keylist.entries.length; e++) {
                        this.crefoChecklist.keylist.entries[e].enabled = false;

                        if (this.crefoChecklist.accepted === null) {
                            continue;
                        }
                        for (var a = 0; a < this.crefoChecklist.accepted.length; a++) {
                            if (this.crefoChecklist.keylist.entries[e].key === this.crefoChecklist.accepted[a]) {
                                this.crefoChecklist.keylist.entries[e].enabled = true;
                            }
                        }
                    }
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Prüfungsliste");
                }
            );
        }
    }
};
</script>
